@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');

.ag-header-cell-text {
  color: #495057;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
}
.ag-cell[col-id="domain_name"] {
  font-size: 13px !important;
}
.ag-cell[col-id="status"], 
.ag-cell[col-id="expire_date"], 
.ag-cell[col-id="rate"],
.ag-cell[col-id="region"],
.ag-cell[col-id="msk_yandex"],
.ag-cell[col-id="spb_yandex"],
.ag-cell[col-id="rus_yandex"],
.ag-cell[col-id="msk_google"],
.ag-cell[col-id="created_date"],
.ag-cell[col-id="accessed"] {
  font-size: 12px !important;
}
.ag-cell {
  color: #495057;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300;
}
.ag-header-row {
    background-color: #fff !important;
}
.ag-root-wrapper {
  border-color: #eff2f7 !important;
}
.ag-row {
  z-index: 0;
}
.ag-row.ag-row-focus {
  z-index: 1;
}
.check-domain {
  margin-top: 10px;
}
.dropdown-item {
  padding: 0px 1.5rem !important;
  font-size: 13px !important;
}
.action-buttons {
  margin: 16px 0 !important;
}
.bx-search-alt {
  cursor: pointer;
}
.not-active {
  color: #495057 !important;
}
.actions i {
  font-size: 20px;
  cursor: pointer;
}